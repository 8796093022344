import type { Pinia, Store } from "pinia";
import { getActivePinia } from "pinia";

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>;
}

export function resetStores() {
  const pinia = getActivePinia() as ExtendedPinia;
  if (pinia) pinia._s.forEach((store) => store.$reset());
}
